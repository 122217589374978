import Axios from 'axios';
import constants from '../constants';
import storage from '../storage';
import { users, usersCount } from './api/users/users.service';
import { hotels, hotelsCount } from './api/hotels/hotels.service';
import { formFields, formFieldsCount } from './api/formFields/formField.service';
import { hotel } from './api/hotels/hotel.get.service';
import { hotelPatch } from './api/hotels/hotel.patch.service';
import { configCheckinPatch } from './api/configCheckin/configCheckin.patch.service';
import { userPatch } from './api/users/user.patch.service';
import { formField } from './api/formFields/formField.get.service';
import { formFieldPatch } from './api/formFields/formField.patch.service';
import { formFieldCreate } from './api/formFields/formField.create.service';

import { useHistory } from 'react-router-dom';

export default function useApi() {
  const history = useHistory();
  setUpInterceptors();

  function setUpInterceptors() {
    Axios.interceptors.request.use(
      request => {
        const storedCredentials = storage.get(constants.AUTH.USER_KEY);
        if (storedCredentials) {
          const { user } = JSON.parse(storedCredentials);
          request.headers.Authorization = `Bearer ${user.stsTokenManager.accessToken}`;
        }
        return request;
      },
      error => {
        return error;
      }
    );
    const responseInterceptor = Axios.interceptors.response.use(
      response => {
        if (isUnauthorizedResponse(response)) {
          Axios.interceptors.response.eject(responseInterceptor);
          console.warn('401 - eject user');
          deleteUserToken();
          return Promise.reject();
        } else {
          return response;
        }
      },
      error => {
        if (error && error.response && isUnauthorizedResponse(error.response)) {
          console.warn('401 - eject user');
          deleteUserToken();
          return Promise.reject();
        } else return rejectError(error);
      }
    );
    function isUnauthorizedResponse(response) {
      return response && response.status === 401;
    }

    function rejectError(error) {
      return Promise.reject(error);
    }
  }
  function deleteUserToken() {
    console.log('fueraa');
    storage.remove(constants.AUTH.USER_KEY);
    history.push('/login');
  }

  return {
    API: {
      users,
      usersCount,
      userPatch,
      hotel,
      hotelPatch,
      configCheckinPatch,
      hotels,
      hotelsCount,
      formFields,
      formFieldsCount,
      formField,
      formFieldCreate,
      formFieldPatch,
    },
  };
}
