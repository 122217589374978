import config from '../../config';
import axios from 'axios';

export function hotel(id, include) {
  const {
    host,
    apiURIs: { hotels },
  } = config;

  const url = `${host}${hotels}/${id}`;

  const options = {
    method: 'GET',
    url,
  };
  if (include) options.params = { include };

  return axios(options).then(({ data }) => {
    return JSON.parse(JSON.stringify(data));
  });
}
