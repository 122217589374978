import config from '../../config';
import axios from 'axios';

export function configCheckinPatch(configCheckinId, properties) {
  const {
    host,
    apiURIs: { hotels },
  } = config;

  const url = `${host}${hotels}/configCheckin/${configCheckinId}`;
  
  const options = {
    method: 'PATCH',
    url,
    data: properties,
  };

  return axios(options);
}
