import { useState } from 'react';
import useApi from '../../../utils/networking/Api.hook';
import { useHistory } from 'react-router-dom';

export default function useHotels() {
  const [hotelsState, setState] = useState({
    data: { items: [], count: 0 },
    params: null,
    loading: false,
  });

  const { API } = useApi();
  const history = useHistory();

  const hotelsActions = {
    loadData: params => {
      const { pagination, sort, where } = params;
      const { pageSize: limit, page } = pagination;
      const { sortField, sortOrder } = sort;
      const offset = (page - 1) * limit;
      const filter = {
        limit,
        offset,
      };
      if (where) filter.where = where;
      if (sortOrder) {
        let order = sortOrder === 'ascend' ? 'ASC' : 'DESC';
        filter.order = `[["${sortField}", "${order}"]]`;
      }

      hotelsActions.setLoading(true);
      return Promise.all([API.hotels(filter), API.hotelsCount(filter)]).then(([data, count]) => {
        updateState({
          data: { count: count, items: data },
          params,
        });
        hotelsActions.setLoading(false);
      });
    },
    setLoading: loading => {
      updateState({ loading });
    },
    onSelectHotel: hotel => {
      history.push(`hotels/${hotel.id}`);
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { hotelsState, hotelsActions };
}
