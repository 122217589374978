import React, { useState, useEffect } from 'react';
import { Table, Button, Input } from 'antd';
import { Link } from 'react-router-dom';
import styles from './CrudList.module.css';

const defaultFilter = {
  limit: 10,
  page: 1,
};

export default function CrudList(props) {
  const {
    title,
    columns,
    filter: customFilter,
    requestMethod,
    data,
    loading,
    createModelUri,
    onSelect = null,
  } = props;
  const dataFilter = {
    ...defaultFilter,
    ...customFilter,
  };

  const [filter, setFilter] = useState({
    pagination: {
      pageSize: dataFilter.limit,
      page: dataFilter.page,
      showSizeChanger: true,
    },
    sort: {},
    searchParams: {
      searchColumn: '',
      searchText: '',
    },
    where: {},
  });

  useEffect(() => {
    requestMethod(filter);
  }, []);

  useEffect(() => {
    const pagination = {
      ...filter.pagination,
      total: data.count,
    };
    setFilter({ ...filter, pagination });
  }, [data]);

  function handleTableChange(pager, filters, sorter) {
    let columnFilters = getColumnFilters(filters);
    const newFilter = {
      ...filter,
      pagination: {
        pageSize: pager.pageSize,
        page: pager.current,
      },
      sort: {
        sortField: sorter.field,
        sortOrder: sorter.order,
      },
      ...columnFilters,
    };
    setFilter(newFilter);
    requestMethod(newFilter);
  }

  function listTitle() {
    let headerTitle = title;
    if (filter.pagination.total > 0) {
      headerTitle = `${filter.pagination.total} ${title}`;
    }
    return headerTitle;
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerTitleContainer}>
        <div className={styles.headerTitle}>{listTitle()}</div>
        {createModelUri && (
          <Link to={createModelUri}>
            <a>
              <Button className={styles.actionButton} type="primary">
                Create
              </Button>
            </a>
          </Link>
        )}
      </div>
      <Table
        className={styles.table}
        columns={columns.map(column => {
          if (column.searchable) {
            column = {
              ...column,
              ...getColumnSearchProps({
                dataIndex: column.dataIndex,
                searchParams: filter.searchParams,
                setSearchParams: searchParams => {
                  setFilter({ ...filter, searchParams });
                },
              }),
            };
          }
          return column;
        })}
        rowKey={record => record.id}
        dataSource={data.items}
        pagination={filter.pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          if (onSelect) {
            return {
              onClick: event => {
                onSelect(record);
              },
            };
          }
        }}
      />
    </div>
  );
}

function getColumnSearchProps({ dataIndex, searchParams, setSearchParams }) {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className={styles.searchPopup}>
        <Input
          ref={node => {
            searchParams.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
            setSearchParams({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
          className={styles.searchInput}
        />
        <div>
          <Button
            type="primary"
            size="small"
            className={styles.searchButton}
            onClick={() => {
              confirm();
              setSearchParams({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setSearchParams({
                searchText: null,
              });
            }}
            size="small"
            className={styles.searchButton}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchParams.searchInput.select());
      }
    },
  };
}

function getColumnFilters(filters) {
  let columnFilters = {};
  if (filters && Object.keys(filters).length) {
    const propertiesToFilter = {};
    Object.keys(filters).forEach(filterKey => {
      if (filters[filterKey] && filters[filterKey].length) {
        propertiesToFilter[filterKey] = {
          $like: `%${filters[filterKey][0]}%`,
        };
      }
    });
    columnFilters = {
      where: {
        ...propertiesToFilter,
      },
    };
  }
  return columnFilters;
}
