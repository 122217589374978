import * as firebase from 'firebase/app';
import 'firebase/auth';
import storage from '../utils/storage';
import constants from '../utils/constants';

const useAuthHook = () => {
  const authActions = {
    emailLogin: (email, password) => {
      return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(( user ) => {
          storage.add(constants.AUTH.USER_KEY, user);
          return JSON.parse(JSON.stringify(user));
        })
        .catch(error => {
          throw error;
        });
    },
    forgetEmail: email => {
      return firebase
        .auth()
        .sendPasswordResetEmail(email)
        .catch(error => {
          throw error;
        });
    },
    currentUser: () => {
      return new Promise(resolve => {
        firebase.auth().onAuthStateChanged(resolve);
      });
    },
    logout: () => {
      storage.remove(constants.AUTH.USER_KEY);
      return firebase
        .auth()
        .signOut()
        .catch(error => {
          throw error;
        });
    },
  };

  return { authActions };
};

export default useAuthHook;
