import React, { useContext } from 'react';
import { Layout, Button } from 'antd';
import styles from './DefaultLayout.module.css';
import { LogoutOutlined } from '@ant-design/icons';
import { AppContext } from '../../App.context';
import { Link, useLocation } from 'react-router-dom';
const { Header, Sider, Content } = Layout;

const routes = [
  { path: '/users', name: 'Users' },
  { path: '/hotels', name: 'Hotels' },
];

export default function DefaultLayout({ children }) {
  const { appActions } = useContext(AppContext);
  let location = useLocation();
  return (
    <div className={styles.container}>
      <Layout className={styles.layout}>
        <Header className={styles.layoutHeader}>
          <b>CIVITFUN INTRANET</b>
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            onClick={() => appActions.auth.logout()}
          ></Button>
        </Header>
        <Layout>
          <Sider className={styles.layoutSider}>
            <ul className={styles.siderItems}>
              {routes.map((route, key) => (
                <Link to={route.path} key={key}>
                  <li
                    className={`${styles.siderItem} ${
                      route.path === location.pathname ? styles.siderItemActive : ''
                    }`}
                  >
                    {route.name}
                  </li>
                </Link>
              ))}
            </ul>
          </Sider>
          <Content className={styles.layoutContent}>{children}</Content>
        </Layout>
      </Layout>
    </div>
  );
}
