import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useApi from '../../../../utils/networking/Api.hook';

export default function useFormFieldDetail() {
  const [formFieldState, setState] = useState({
    data: null,
    hotelData: null,
    hotelLanguages: [],
    loading: false,
  });
  const { hotelId, id } = useParams();
  const history = useHistory();
  const { API } = useApi();

  useEffect(() => {
    if (id === 'new') formFieldActions.loadHotelLanguages();
    else Promise.all([formFieldActions.loadData(), formFieldActions.loadHotelLanguages()]);
  }, []);

  const formFieldActions = {
    loadData: () => {
      formFieldActions.setLoading(true);

      const formFieldInclude = JSON.stringify([
        { model: 'AppConfig', where: { hotelId } },
        { model: 'FormFieldOptions', include: [{ model: 'FormFieldOptionTexts' }] },
      ]);

      return API.formField(id, formFieldInclude).then(data => {
        updateState({
          data,
        });

        formFieldActions.setLoading(false);
      });
    },
    loadHotelLanguages: () => {
      formFieldActions.setLoading(true);
      const hotelInclude = JSON.stringify([
        { model: 'AppConfig', include: [{ model: 'AppConfigLanguages', include: ['Language'] }] },
      ]);
      return API.hotel(hotelId, hotelInclude).then(hotelData => {
        const languages = hotelData.appConfig.appConfigLanguages.map(appConfigLang => {
          return appConfigLang.language.code;
        });
        updateState({
          hotelData,
          hotelLanguages: languages,
        });
        formFieldActions.setLoading(false);
      });
    },
    patchOrCreate: () => {
      if (formFieldState.data?.type === 'select') {
        if (!formFieldState.data?.formFieldOptions?.length) {
          alert('Select options are mandatory');
          return;
        } else {
          const missingValue = formFieldState.data?.formFieldOptions?.find(field => !field.value);
          if (missingValue) {
            alert(`Select options with empty value are not allowed`);
            return;
          }
        }
      }
      id === 'new' ? formFieldActions.createFormField() : formFieldActions.patchFormFieldData();
    },
    createFormField: () => {
      formFieldActions.setLoading(true);

      const formFieldData = { ...formFieldState.data };
      formFieldData.appConfigId = formFieldState.hotelData.appConfig.id;

      if (formFieldState.data.type !== 'select') formFieldData.formFieldOptions = [];

      API.formFieldCreate(formFieldData).then(({ data }) => {
        const { id: newId } = data;
        history.push(`/hotel/${hotelId}/formFields/${newId}`);
        history.go();
        formFieldActions.setLoading(false);
      });
    },
    patchFormFieldData: () => {
      formFieldActions.setLoading(true);
      const formFieldData = { ...formFieldState.data };
      if (formFieldState.data.type !== 'select') formFieldData.formFieldOptions = [];
      API.formFieldPatch(id, formFieldData).then(res => {
        formFieldActions.loadData();
        formFieldActions.setLoading(false);
      });
    },
    setLoading: loading => {
      if (!formFieldState.loading) updateState({ loading });
    },
    updateData: values => {
      updateState({ data: { ...formFieldState.data, ...values } });
    },
    updateFieldOptions: value => {
      //needs a shallow copy to update values
      const newFormFieldOptions = formFieldState.data.formFieldOptions
        ? [...formFieldState.data.formFieldOptions]
        : [];

      if (value.id) {
        const index = newFormFieldOptions.findIndex(field => value.id === field.id);
        newFormFieldOptions[index] = value;
      } else if (value.tmpId) {
        const index = newFormFieldOptions.findIndex(field => value.tmpId === field.tmpId);
        if (index > -1) newFormFieldOptions[index] = value;
        else newFormFieldOptions.push(value);
      } else {
        newFormFieldOptions.push(value);
      }

      formFieldActions.updateData({ formFieldOptions: newFormFieldOptions });
    },
    deleteFieldOptions: value => {
      const newFormFieldOptions = [...formFieldState.data.formFieldOptions];
      let index = -1;
      if (value.id) {
        index = newFormFieldOptions.findIndex(field => value.id === field.id);
      } else if (value.tmpId) {
        index = newFormFieldOptions.findIndex(field => value.tmpId === field.tmpId);
      }
      if (index > -1) {
        newFormFieldOptions.splice(index, 1);
        formFieldActions.updateData({ formFieldOptions: newFormFieldOptions });
      }
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { formFieldState, formFieldActions };
}
