import React, { useContext } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import styles from './Login.module.css';
import { AppContext } from '../../App.context';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function Login() {
  const { appActions } = useContext(AppContext);

  const onFinish = values => {
    const { email, password } = values;
    appActions.auth.emailLogin(email, password);
  };

  const onFinishFailed = errorInfo => {};

  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src="https://www.civitfun.com/public/img/logotipos/logo_civitfun.png"
        alt="Civitfun"
      />
      <div className={styles.form}>
        <Form
          className={styles.formulario}
          {...layout}
          name="login"
          initialValues={{
            email: '',
            password: '',
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Usuario"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
