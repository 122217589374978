import React from 'react';
import { Spin, Space } from 'antd';

export default function Loading() {
  return (
    <Space style={{ position: 'absolute', zIndex: 100 }} size="middle">
      <Spin size="large" />
    </Space>
  );
}
