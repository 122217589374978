export default {
  AUTH: {
    USER_KEY: 'user',
  },
  CHECKIN_MODULE: [
    'API',
    'Civitfun',
    'WinhotelTest',
    'Apaleo',
    'Avalon',
    'Avalon2',
    'Catalonia',
    'Cloudbeds',
    'Excellence',
    'Guestline',
    'GuestPortal',
    'Mews',
    'Navision',
    'NoIntegration',
    'Noray',
    'Ohip',
    'Opera',
    'Phantom',
    'Porthotels',
    'Poseidon',
    'Protel',
    'Rms',
    'RoomCloud',
    'Sihot',
    'Standard',
    'Standard2',
    'Standard3',
    'Stelle',
    'Tecsoft',
    'Timon',
    'Totvs',
    'UlysesCloud',
    'Winhotel',
    'Bookipro',
  ],
  PMS_FORMAT: ['soap', 'json', 'soap12'],
  FORM_FIELDS: {
    STATE: ['active', 'disabled', 'removed'],
    TYPE: ['email', 'text', 'date', 'numeric', 'select', 'checkbox'],
    FORM: ['checkin', 'guest', 'noShow', 'noShowGuest', 'customPMS', 'checkout'],
  },
  HOTEL_STATES: { created: 1, onboarding: 2, live: 3, out_of_licence: 4, lost: 5, demo: 6, pending: 7 },
};
