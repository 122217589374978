import { useState } from 'react';
import useApi from '../../../utils/networking/Api.hook';
import { useHistory, useParams } from 'react-router-dom';

export default function useFormFields() {
  const [formFieldsState, setState] = useState({
    data: { items: [], count: 0 },
    params: null,
    loading: false,
  });

  const { API } = useApi();
  const history = useHistory();
  const { hotelId } = useParams();

  const formFieldsActions = {
    loadData: params => {
      const { pagination, sort, where } = params;
      const { pageSize: limit, page } = pagination;
      const { sortField, sortOrder } = sort;
      const offset = (page - 1) * limit;
      const filter = {
        limit,
        offset,
      };
      if (where) {
        filter.where = where;
      }
      if (sortOrder) {
        let order = sortOrder === 'ascend' ? 'ASC' : 'DESC';
        filter.order = `[["${sortField}", "${order}"]]`;
      }
      filter.include = JSON.stringify([{ model: 'AppConfig', where: { hotelId } }]);

      formFieldsActions.setLoading(true);
      return Promise.all([API.formFields(filter), API.formFieldsCount(filter)]).then(
        ([data, count]) => {
          updateState({
            data: { count: count, items: data },
            params,
          });
          formFieldsActions.setLoading(false);
        }
      );
    },
    setLoading: loading => {
      updateState({ loading });
    },
    onSelectHotel: hotel => {
      history.push(`formFields/${hotel.id}`);
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { formFieldsState, formFieldsActions };
}
