import React from 'react';
import { Form, Select } from 'antd';

export default function SelectInput({
  label,
  name,
  values,
  currentValue,
  styles = {},
  onChange = () => {},
}) {
  const { Option } = Select;
  return (
    <Form.Item label={label} name={name}>
      <Select style={{ ...styles }} value={currentValue} onChange={onChange}>
        {values.map((option, idx) => {
          return (
            <Option key={`${option}-${idx}`} value={option}>
              {typeof option === 'string' ? option.toLowerCase() : option}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}
