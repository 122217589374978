import config from '../../config';
import axios from 'axios';

export function formFieldPatch(formFieldId, properties) {
  const {
    host,
    apiURIs: { formFields },
  } = config;

  const url = `${host}${formFields}/${formFieldId}`;

  const options = {
    method: 'PATCH',
    url,
    data: properties,
  };

  return axios(options);
}
