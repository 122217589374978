import React from 'react';
import constants from '../../../../../utils/constants';
import { Button, Input, Table, Row, Col } from 'antd';
import { useContext } from 'react';
import { FormFieldDetailContext } from '../FormFieldDetail.context';
import styles from './FormFieldOptionsTable.module.css';
import { DeleteOutlined } from '@ant-design/icons';

export default function FormFieldOptionsTable() {
  const { formFieldState, formFieldActions } = useContext(FormFieldDetailContext);

  const columns = [
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: renderValue,
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      render: renderTranslations,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: renderDeleteValue,
    },
  ];

  return (
    <Row style={{ width: '100%' }}>
      <Col lg={24}>
        <div className={styles.tableContainer}>
          <Table
            pagination={false}
            dataSource={formFieldState.data?.formFieldOptions}
            columns={columns}
            title={() => (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Opciones del select (desplegable)</span>
                <div>
                  <Button onClick={() => addOption()}>Crear nuevo</Button>
                </div>
              </div>
            )}
          />
        </div>
      </Col>
    </Row>
  );

  function addOption() {
    formFieldActions.updateFieldOptions({
      tmpId: createTmpId(),
      value: '',
      formFieldOptionTexts: [],
    });

    function createTmpId() {
      return Math.random().toString(36).substr(2, 5);
    }
  }

  function removeOption(record) {
    console.log('remove', record);
    formFieldActions.deleteFieldOptions(record);
  }

  function renderValue(value, record) {
    return (
      <Input
        value={value}
        onChange={event => {
          record.value = event.target.value;
          formFieldActions.updateFieldOptions(record);
        }}
      />
    );
  }

  function renderDeleteValue(value, record) {
    return <Button icon={<DeleteOutlined />} onClick={() => removeOption(record)} />;
  }

  function renderTranslations(value, record) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {formFieldState.hotelLanguages.map(language => {
          const formFieldOptionText = record.formFieldOptionTexts.find(
            formFieldOptionText => formFieldOptionText.lang == language
          );
          return (
            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: '50px', marginRight: 10 }}>{language}</div>
              <div>
                <Input
                  value={formFieldOptionText?.value}
                  onChange={event => {
                    if (formFieldOptionText) {
                      formFieldOptionText.value = event.target.value;
                    } else {
                      record.formFieldOptionTexts.push({
                        value: event.target.value,
                        lang: language,
                      });
                    }
                    formFieldActions.updateFieldOptions(record);
                  }}
                ></Input>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
