import React from 'react';
import useFormFieldDetail from './FormFieldDetail.hook';
import DefaultLayout from '../../../DefaultLayout/DefaultLayout';
import Loading from '../../../common/Loader/Loading';
import styles from './FormFieldDetail.module.css';
import { Button, Input, Form } from 'antd';
import FormText from '../../../common/form-items/FormText';
import constants from '../../../../utils/constants';
import SelectInput from '../../../common/SelectInput/SelectInput';
import FormFieldOptionsTable from './FormFieldOptionsTable/FormFieldOptionsTable';
import { FormFieldDetailContext } from './FormFieldDetail.context';
import { useParams } from 'react-router-dom';

export default function FormFieldDetail() {
  const { formFieldState, formFieldActions } = useFormFieldDetail();
  const { id } = useParams();

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const { STATE, TYPE, FORM } = constants.FORM_FIELDS;
  return (
    <DefaultLayout>
      <div className={styles.container}>
        {formFieldState.loading && <Loading />}
        {(formFieldState.data || id === 'new') && (
          <>
            <div className={styles.form}>
              <Form
                {...layout}
                name="formField"
                initialValues={formFieldState.data}
                onValuesChange={data => {
                  formFieldActions.updateData(data);
                }}
              >
                <Form.Item label="ID" name="id">
                  <FormText>{formFieldState.data?.id}</FormText>
                </Form.Item>

                <Form.Item label="Name" name="name">
                  <Input />
                </Form.Item>

                <SelectInput
                  label="Form"
                  name="form"
                  values={FORM}
                  currentValue={formFieldState.data?.form}
                />

                <SelectInput
                  label="Type"
                  name="type"
                  values={TYPE}
                  currentValue={formFieldState.data?.type}
                />

                <SelectInput
                  label="State"
                  name="state"
                  values={STATE}
                  currentValue={formFieldState.data?.state}
                />

                <SelectInput
                  label="Required"
                  name="required"
                  values={[1, 0]}
                  currentValue={formFieldState.data?.required}
                />
              </Form>
            </div>
            {formFieldState.data?.type === 'select' && (
              <FormFieldDetailContext.Provider value={{ formFieldState, formFieldActions }}>
                <FormFieldOptionsTable />
              </FormFieldDetailContext.Provider>
            )}
            <div className={styles.buttonContainer}>
              <Button type="primary" onClick={() => formFieldActions.patchOrCreate()}>
                Guardar
              </Button>
            </div>
          </>
        )}
      </div>
    </DefaultLayout>
  );
}
