import config from '../../config';
import axios from 'axios';

export function formFields(params) {
  const {
    host,
    apiURIs: { formFields },
  } = config;

  const url = `${host}${formFields}`;
  const { limit, offset, where, order, include } = params;

  const options = {
    method: 'GET',
    url,
    params: { limit, offset },
  };
  if (where) options.params.where = where;
  if (order) options.params.order = order;
  if (include) options.params.include = include;

  return axios(options).then(({ data }) => {
    return JSON.parse(JSON.stringify(data));
  });
}

export function formFieldsCount(params) {
  const {
    host,
    apiURIs: { formFieldsCount },
  } = config;

  const url = `${host}${formFieldsCount}`;
  const { where, include } = params;

  const options = {
    method: 'GET',
    url,
  };
  if (where) options.params = { where };
  if (include) options.params.include = include;

  return axios(options).then(({ data }) => data.count);
}
