import { useState } from 'react';
import useApi from '../../../utils/networking/Api.hook';

export default function useUsers() {
  const [usersState, setState] = useState({
    data: { items: [], count: 0 },
    params: null,
    statusSelector: [],
    loading: false,
  });

  const { API } = useApi();

  const usersActions = {
    loadData: params => {
      const { pagination, sort, where } = params;
      const { pageSize: limit, page } = pagination;
      const { sortField, sortOrder } = sort;
      const offset = (page - 1) * limit;
      const filter = {
        limit,
        offset,
      };
      if (where) filter.where = where;
      if (sortOrder) {
        let order = sortOrder === 'ascend' ? 'ASC' : 'DESC';
        filter.order = `[["${sortField}", "${order}"]]`;
      }

      usersActions.setLoading(true);
      const include = JSON.stringify([{ model: 'HotelUser', include: [{ model: 'Hotel' }] }]);
      return Promise.all([API.users(filter, include), API.usersCount(filter)]).then(
        ([data, count]) => {
          updateState({
            data: { count, items: data },
            params,
            statusSelector: data.map(item => item.status),
          });
          usersActions.setLoading(false);
        }
      );
    },
    userPatch: (userId, properties) => {
      return API.userPatch(userId, properties);
    },
    setStatusSelector: status => {
      updateState({ statusSelector: status });
    },
    setLoading: loading => {
      updateState({ loading });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { usersState, usersActions };
}
