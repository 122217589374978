import config from '../../config';
import axios from 'axios';

export function users(params, include) {
  const {
    host,
    apiURIs: { users },
  } = config;

  const url = `${host}${users}`;
  const { limit, offset, where, order } = params;

  const options = {
    method: 'GET',
    url,
    params: { limit, offset },
  };
  if (where) options.params.where = where;
  if (order) options.params.order = order;
  if (include) options.params.include = include;
  
  return axios(options).then(({ data }) => {
    return JSON.parse(JSON.stringify(data));
  });
}

export function usersCount(params) {
  const {
    host,
    apiURIs: { usersCount },
  } = config;

  const url = `${host}${usersCount}`;
  const { where } = params;

  const options = {
    method: 'GET',
    url,
  };
  if (where) options.params = { where };

  return axios(options).then(({ data }) => data.count);
}
