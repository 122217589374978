import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from '../Components/Login/Login.jsx';
import history from './history';
import Users from '../Components/Dashboard/Users/Users';
import Hotels from '../Components/Dashboard/Hotels/Hotels';
import PrivateRoute from './PrivateRoute';
import HotelDetail from '../Components/Dashboard/Hotels/Hotel-Detail/HotelDetail.jsx';
import FormFields from '../Components/Dashboard/FormFields/FormFields.jsx';
import FormFieldDetail from '../Components/Dashboard/FormFields/FormFieldDetail/FormFieldDetail.jsx';

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/users">
          <Users />
        </PrivateRoute>
        <PrivateRoute exact path="/hotels">
          <Hotels />
        </PrivateRoute>
        <PrivateRoute exact path="/hotels/:id">
          <HotelDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/hotel/:hotelId/formFields">
          <FormFields />
        </PrivateRoute>
        <PrivateRoute exact path="/hotel/:hotelId/formFields/:id">
          <FormFieldDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <Redirect to="/users" />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
