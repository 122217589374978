import React from 'react';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import CrudList from '../../common/CrudList/CrudList';
import { Select, Popover, Button, message } from 'antd';
import useUsers from './Users.hook';
const { Option } = Select;

export default function Users() {
  const { usersState, usersActions } = useUsers();

  /**
const hotels = hotelUsers.map({hotel} => name;
const hotelNames = hotels.join(',');
   */
  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      searchable: true,
      sorter: true,
      width: 50
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      searchable: true,
      sorter: true,
      width: 50
    },
    {
      title: 'hotel',
      dataIndex: 'hotel',
      key: 'hotel',
      render: renderHotelNames,
      width: 50
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
    },
    {
      title: 'trial expiration date',
      dataIndex: 'trialExpirationDate',
      key: 'trialExpirationDate',
      sorter: true,
    },
    {
      title: 'validEmail',
      dataIndex: 'validEmail',
      key: 'validEmail',
      sorter: true,
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'status',
      render: renderActions,
    },
  ];

  return (
    <DefaultLayout>
      <CrudList
        title={'Usuarios'}
        columns={columns}
        data={usersState.data}
        loading={usersState.loading}
        requestMethod={usersActions.loadData}
      />
    </DefaultLayout>
  );

  function renderHotelNames(text, record, index) {
    const hotels = record.hotelUsers.map(({ hotel }) => hotel.name);
    return hotels.join(' | ');
  }

  function renderActions(text, record, index) {
    const statePopver = (
      <div>
        <Select
          style={{ width: 120 }}
          value={usersState.statusSelector[index]}
          onChange={value => {
            const newStatusSelector = { ...usersState.statusSelector };
            newStatusSelector[index] = value;
            usersActions.setStatusSelector(newStatusSelector);
          }}
        >
          <Option value="active">Active</Option>
          <Option value="disabled">Disabled</Option>
          <Option value="removed">Removed</Option>
        </Select>
        <Button
          onClick={() => {
            usersActions
              .userPatch(record.id, { status: usersState.statusSelector[index] })
              .then(() => {
                message.success('Guardado correctamente');
                usersActions.loadData(usersState.params);
              })
              .catch(() => {
                message.error('Error al guardar');
              });
          }}
        >
          Cambiar
        </Button>
      </div>
    );
    return (
      <div>
        <Popover content={statePopver} title="Cambiar estado" trigger="click">
          <Button>Cambiar estado</Button>
        </Popover>
        <Button
          onClick={() => {
            usersActions
              .userPatch(record.id, { trialExpirationDate: null })
              .then(() => {
                message.success('Guardado correctamente');
                usersActions.loadData(usersState.params);
              })
              .catch(() => {
                message.error('Error al guardar');
              });
          }}
        >
          Borrar trial expiration date
        </Button>
        <Button
          onClick={() => {
            usersActions
              .userPatch(record.id, { validEmail: 1 })
              .then(() => {
                message.success('Guardado correctamente');
                usersActions.loadData(usersState.params);
              })
              .catch(() => {
                message.error('Error al guardar');
              });
          }}
        >
          Validar email
        </Button>
        <Popover content={<div>{record.password}</div>}>
          <Button>Ver password</Button>
        </Popover>
      </div>
    );
  }
}
