const add = (key, item) => {
  if (item) {
    item = typeof item === 'object' ? JSON.stringify(item) : item;
    localStorage.setItem(key, item);
  }
};

const remove = key => {
  if (!!get(key)) {
    localStorage.removeItem(key);
  }
};

const get = key => {
  return localStorage.getItem(key);
};

const storage = {
  add,
  remove,
  get,
};

export default storage;
