import config from '../../config';
import axios from 'axios';

export function formField(id, include) {
  const {
    host,
    apiURIs: { formFields },
  } = config;

  const url = `${host}${formFields}/${id}`;

  const options = {
    method: 'GET',
    url,
  };
  if (include) options.params = {include};

  return axios(options).then(({ data }) => {
    return JSON.parse(JSON.stringify(data));
  });
}
