import React from 'react';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import CrudList from '../../common/CrudList/CrudList';
import useHotels from './Hotels.hook';

export default function Hotels() {
  const { hotelsState, hotelsActions } = useHotels();

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      searchable: true,
      sorter: true,
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      searchable: true,
      sorter: true,
    },
  ];

  return (
    <DefaultLayout>
      <CrudList
        title={'Hoteles'}
        columns={columns}
        data={hotelsState.data}
        loading={hotelsState.loading}
        requestMethod={hotelsActions.loadData}
        onSelect={hotelsActions.onSelectHotel}
      />
    </DefaultLayout>
  );
}
