export default {
  host: process.env.NODE_ENV==='development'
    ? 'http://localhost:8000/'
    : 'https://intranet-api.civitfun.com/',
  // host: 'http://localhost:5001/civitfun-intranet/us-central1/',
  apiURIs: {
    users: 'users',
    usersCount: 'users/count',
    hotels: 'hotels',
    hotelsCount: 'hotels/count',
    formFields: 'formFields',
    formFieldsCount: 'formFields/count',
  },
};
