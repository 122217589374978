import React from 'react';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import CrudList from '../../common/CrudList/CrudList';
import useFormFields from './FormFields.hook';
import { useParams } from 'react-router-dom';
export default function FormFields() {
  const { formFieldsState, formFieldsActions } = useFormFields();
  const { hotelId } = useParams();

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      searchable: true,
      sorter: true,
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
      searchable: true,
      sorter: true,
    },
    {
      title: 'state',
      dataIndex: 'state',
      key: 'state',
      searchable: true,
      sorter: true,
    },
    {
      title: 'required',
      dataIndex: 'required',
      key: 'required',
      searchable: true,
      sorter: true,
    },
    {
      title: 'form',
      dataIndex: 'form',
      key: 'form',
      searchable: true,
      sorter: true,
    },
  ];

  return (
    <DefaultLayout>
      <CrudList
        title={'Campos de formulario'}
        createModelUri={`/hotel/${hotelId}/formFields/new`}
        filter={{limit:100}}
        columns={columns}
        data={formFieldsState.data}
        loading={formFieldsState.loading}
        requestMethod={formFieldsActions.loadData}
        onSelect={formFieldsActions.onSelectHotel}
      />
    </DefaultLayout>
  );
}
