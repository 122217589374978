import config from '../../config';
import axios from 'axios';

export function hotels(params) {
  const {
    host,
    apiURIs: { hotels },
  } = config;

  const url = `${host}${hotels}`;
  const { limit, offset, where, order } = params;

  const options = {
    method: 'GET',
    url,
    params: { limit, offset },
  };
  if (where) options.params.where = where;
  if (order) options.params.order = order;

  return axios(options).then(({ data }) => {
    return JSON.parse(JSON.stringify(data));
  });
}

export function hotelsCount(params) {
  const {
    host,
    apiURIs: { hotelsCount },
  } = config;

  const url = `${host}${hotelsCount}`;
  const { where } = params;

  const options = {
    method: 'GET',
    url,
  };
  if (where) options.params = { where };

  return axios(options).then(({ data }) => data.count);
}
