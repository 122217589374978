import React from 'react';
import { AppContext } from './App.context';
import useAppHook from './App.hook';
import 'antd/dist/antd.css';
import Routes from './Routes/Routes';
import styles from './App.module.css';

function App() {
  const { appState, appActions } = useAppHook();
  return (
    <div className={styles.app}>
      <AppContext.Provider value={{ appState, appActions }}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}

export default App;
