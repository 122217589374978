import config from '../../config';
import axios from 'axios';

export function formFieldCreate(properties) {
  const {
    host,
    apiURIs: { formFields },
  } = config;

  const url = `${host}${formFields}`;

  const options = {
    method: 'POST',
    url,
    data: properties,
  };

  return axios(options);
}
