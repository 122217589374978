import { useState, useEffect } from 'react';
import useAuthHook from './firebase/auth.hook';
import * as firebase from 'firebase/app';
import storage from './utils/storage';
import constants from './utils/constants';
import history from './Routes/history';

const useAppHook = () => {
  const [appState, setState] = useState(initialState());
  const { authActions } = useAuthHook();

  useEffect(() => {
    appActions.initialize();
  }, []);

  const appActions = {
    initialize: () => {
      firebase.initializeApp({
        apiKey: 'AIzaSyBrhvGMB3czCOwVFsVUXRrhwAt7EYe8GaY',
        authDomain: 'civitfun-intranet.firebaseapp.com',
        databaseURL: 'https://civitfun-intranet.firebaseio.com',
        projectId: 'civitfun-intranet',
        storageBucket: 'civitfun-intranet.appspot.com',
        messagingSenderId: '958751970942',
        appId: '1:958751970942:web:cf13a05079a1c3f77fea2f',
      });
    },
    auth: {
      ...authActions,
      emailLogin: (email, password) =>
        authActions.emailLogin(email, password).then(({ user }) => {
          updateState({ email: user.email, token: user.stsTokenManager.accessToken });
          history.replace('/users');
          return Promise.resolve();
        }),
      logout: () =>
        authActions.logout().then(() => {
          updateState({ token: null, email: null });
        }),
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { appState, appActions };
};

function initialState() {
  const storedCredentials = storage.get(constants.AUTH.USER_KEY);
  let initialState = {};
  if (storedCredentials) {
    const { user } = JSON.parse(storedCredentials);
    initialState = { email: user.email, token: user.stsTokenManager.accessToken };
  }
  return initialState;
}

export default useAppHook;
